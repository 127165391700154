<template>
  <!-- <div class="layout-menu-container flex flex-column"> -->
  <div class="layout-menu-container">
    <div v-if="authStore.computedRole === 'manager'" class="px-3 pt-4 w-full">
      <label class="block text-sm mb-2 font-bold">
        {{ managerStore.contracts.length > 1 ? 'PICK' : '' }} COMPLEX
      </label>
      <Dropdown
        v-model="managerStore.selectedContract"
        :placeholder="computedPlaceholder"
        class="p-column-filter w-full mb-3"
        :options="managerStore.contracts"
        @change="handleChangeCurrentPage"
        :loading="isLoading"
        :disabled="isLoading || managerStore.contracts.length < 2"
        :dropdownIcon="computedDropdownIcon"
      >
        <template #value="slotProps">
          <div v-if="slotProps.value">
            <span class="block">{{ slotProps.value.name }}</span>
            <span class="text-sm">{{ slotProps.value.address }}</span>
          </div>
          <span v-else>{{ slotProps.placeholder }}</span>
        </template>
        <template #option="slotProps">
          <div>
            <span class="block">{{ slotProps.option.name }}</span>
            <span class="text-sm">{{ slotProps.option.address }}</span>
          </div>
        </template>
      </Dropdown>

      <AppImage
        v-if="managerStore.selectedContract"
        :preview="true"
        imageClass="gallery-image"
        :src="managerStore.selectedContract.contract_image"
      />
      <Skeleton v-else class="mt-3" height="200px"> </Skeleton>
    </div>
    <div v-if="authStore.computedRole === 'external'" class="px-3 pt-4 w-full">
      <label class="block text-sm mb-2 font-bold">
        {{ externalUserStore.contracts.length > 1 ? 'PICK' : '' }} COMPLEX
      </label>
      <Dropdown
        v-model="externalUserStore.selectedContract"
        :placeholder="computedExternalPlaceholder"
        class="p-column-filter w-full mb-3"
        :options="externalUserStore.contracts"
        @change="handleChangeCurrentPage"
        :loading="isLoading"
        :disabled="isLoading || externalUserStore.contracts.length < 2"
        :dropdownIcon="computedExternalDropdownIcon"
      >
        <template #value="slotProps">
          <div v-if="slotProps.value">
            <span class="block">{{ slotProps.value.name }}</span>
            <span class="text-sm">{{ slotProps.value.address }}</span>
          </div>
          <span v-else>{{ slotProps.placeholder }}</span>
        </template>
        <template #option="slotProps">
          <div>
            <span class="block">{{ slotProps.option.name }}</span>
            <span class="text-sm">{{ slotProps.option.address }}</span>
          </div>
        </template>
      </Dropdown>

      <AppImage
        v-if="externalUserStore.selectedContract"
        :preview="true"
        imageClass="gallery-image"
        :src="externalUserStore.selectedContract.contract_image"
      />
      <Skeleton v-else class="mt-3" height="200px"> </Skeleton>
    </div>
    <div v-if="authStore.role === 'super_admin'" class="px-3 pt-4 w-full">
      <FormGroup label="Select Timezone">
        <Dropdown
          :options="timezones"
          filter
          placeholder="Select Timezone"
          class="w-full"
          :virtualScrollerOptions="{ itemSize: 38 }"
          v-model="authStore.timezone"
        >
        </Dropdown>
      </FormGroup>
    </div>

    <AppSubmenu
      class="layout-menu"
      :items="computedMenuItems || []"
      :parentMenuItemActive="true"
      :root="true"
    />
    <div v-if="NODE_ENV !== 'production'" class="mt-auto px-2 py-4 font-light">
      <div class="text-xs">
        <span class="block">
          {{ computedTime }}
        </span>
        <span class="block">
          {{ computedUTCTime }}
        </span>
        <span class="block">
          {{ computedTimezoneTime }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { timezones } from '~/constants/timezones';
import { useExternalUserStore } from '~/stores/externalUserStore';

import type { MenuItem } from '~/types';

const { computedTime, computedTimezoneTime, computedUTCTime } = useTime();
const runtimeConfig = useRuntimeConfig();
const NODE_ENV = runtimeConfig.public.nodeEnv;

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const managerStore = useManagerStore();
const externalUserStore = useExternalUserStore();
const towUserStore = useTowUserStore();
const isLoading = ref(false);
const {
  $auth: { logOut },
} = useNuxtApp();

const menuItems = ref<Array<MenuItem>>([
  {
    label: 'Account',
    icon: 'pi pi-fw pi-home',
    visible: () => {
      return true;
    },
    items: [
      {
        label: 'Account Settings',
        icon: 'pi pi-fw pi-user',
        to: '/account/profile',
      },
      {
        label: 'Log out',
        icon: 'pi pi-fw pi-sign-out',
        command: async () => {
          await logOut();
          return navigateTo('/login');
        },
      },
    ],
  },
]);

const computedPlaceholder = computed(() => {
  if (isLoading.value) {
    return 'Loading...';
  } else if (managerStore.contracts.length === 0) {
    return 'Empty';
  } else if (managerStore.contracts.length && !managerStore.selectedContract) {
    return 'Choose complex';
  }
});

const computedExternalPlaceholder = computed(() => {
  if (isLoading.value) {
    return 'Loading...';
  } else if (externalUserStore.contracts.length === 0) {
    return 'Empty';
  } else if (
    externalUserStore.contracts.length &&
    !externalUserStore.selectedContract
  ) {
    return 'Choose complex';
  }
});

const computedDropdownIcon = computed(() => {
  if (managerStore.contracts.length === 0) {
    return 'pi pi-fw pi-times';
  } else if (managerStore.contracts.length === 1) {
    return 'pi pi-fw pi-lock';
  } else {
    return 'pi pi-fw pi-chevron-down';
  }
});

const computedExternalDropdownIcon = computed(() => {
  if (externalUserStore.contracts.length === 0) {
    return 'pi pi-fw pi-times';
  } else if (externalUserStore.contracts.length === 1) {
    return 'pi pi-fw pi-lock';
  } else {
    return 'pi pi-fw pi-chevron-down';
  }
});

// if dropdown value changes, update current page with new contract id
const handleChangeCurrentPage = (e: any) => {
  if (managerStore.selectedContract && route.fullPath.includes('/complexes')) {
    router.push({
      path: route.fullPath,
      query: { id: managerStore.selectedContract.id },
    });
  }
  if (
    externalUserStore.selectedContract &&
    route.fullPath.includes('/complexes')
  ) {
    router.push({
      path: route.fullPath,
      query: { id: externalUserStore.selectedContract.id },
    });
  }
};

// if selected contract, add dynamic contract links to menu items
const computedMenuItems = computed(() => {
  if (authStore.computedRole === 'manager' && managerStore.selectedContract) {
    const dynamicContractLinks: MenuItem = {
      label: 'Complex Management',
      icon: 'pi pi-fw pi-home',
      visible: () => {
        return true;
      },
    };

    if (authStore.role === 'contract_manager') {
      dynamicContractLinks.items = [
        {
          label: 'Units',
          icon: 'pi pi-fw pi-home',
          to: `/complexes/units?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'Complex Details',
          icon: 'pi pi-fw pi-building',
          to: `/complexes/details?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'Vehicles',
          icon: 'pi pi-fw pi-fw pi-car',
          to: `/complexes/vehicles?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'History',
          icon: 'pi pi-fw pi-history',
          to: `/complexes/history-logs?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'Blocked Vehicles',
          icon: 'pi pi-fw pi-ban',
          to: `/complexes/blocked-vehicles?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'VIP Vehicles',
          icon: 'pi pi-fw pi-star',
          to: `/complexes/vip-vehicles?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'Tows',
          icon: 'game-icons:tow-truck',
          to: `/complexes/tows?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'Sticker Lists',
          icon: 'pi pi-fw pi-tag',
          to: `/complexes/sticker-lists?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'Request a Tow',
          icon: 'fluent:person-phone-24-regular',
          to: `/complexes/create-tow`,
        },
        {
          label: 'Complex Settings',
          icon: 'pi pi-fw pi-briefcase',
          to: `/complexes/settings?id=${managerStore.selectedContract.id}`,
        },
      ];
    } else if (authStore.role === 'contract_staff') {
      dynamicContractLinks.items = [
        {
          label: 'Units',
          icon: 'pi pi-fw pi-home',
          to: `/complexes/units?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'Complex Details',
          icon: 'pi pi-fw pi-building',
          to: `/complexes/details?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'Vehicles',
          icon: 'pi pi-fw pi-car',
          to: `/complexes/vehicles?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'History',
          icon: 'pi pi-fw pi-history',
          to: `/complexes/history-logs?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'Blocked Vehicles',
          icon: 'pi pi-fw pi-ban',
          to: `/complexes/blocked-vehicles?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'VIP Vehicles',
          icon: 'pi pi-fw pi-star',
          to: `/complexes/vip-vehicles?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'Tows',
          icon: 'game-icons:tow-truck',
          to: `/complexes/tows?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'Sticker Lists',
          icon: 'pi pi-fw pi-tag',
          to: `/complexes/sticker-lists?id=${managerStore.selectedContract.id}`,
        },
        {
          label: 'Request a Tow',
          icon: 'fluent:person-phone-24-regular',
          to: `/complexes/create-tow`,
        },
      ];
    }

    return [dynamicContractLinks, ...menuItems.value];
  } else if (authStore.computedRole === 'tow') {
    const dynamicContractLinks: MenuItem = {
      label: 'Complex Management',
      icon: 'pi pi-fw pi-home',
      visible: () => {
        return true;
      },
    };

    const towslipMenu: MenuItem = {
      label: 'towslip menu',
      icon: 'pi pi-fw pi-home',
      visible: () => {
        return true;
      },
    };

    if (towUserStore.towContracts.length > 0) {
      if (authStore.role === 'tow_driver') {
        dynamicContractLinks.items = [
          {
            label: 'Insights',
            icon: 'gridicons:line-graph',
            to: `/insights`,
          },
          {
            label: 'Vehicles',
            icon: 'pi pi-fw pi-car',
            to: `/vehicles`,
          },
          {
            label: 'Sticker Vehicles',
            icon: 'pi pi-fw pi-tag',
            to: `/sticker-lists`,
          },
        ];
        towslipMenu.items = [
          {
            label: 'Tows',
            icon: 'game-icons:tow-truck',
            to: `/tows`,
          },
        ];
      } else if (authStore.role === 'tow_staff') {
        dynamicContractLinks.items = [
          {
            label: 'Insights',
            icon: 'ic:twotone-auto-graph',
            to: `/insights`,
          },
          {
            label: 'Apartment complexes',
            icon: 'pi pi-fw pi-home',
            to: `/complexes-managers`,
          },
          {
            label: 'Vehicles',
            icon: 'pi pi-fw pi-car',
            to: `/vehicles`,
          },
          {
            label: 'Sticker Vehicles',
            icon: 'pi pi-fw pi-tag',
            to: `/sticker-lists`,
          },
          {
            label: 'History',
            icon: 'pi pi-fw pi-history',
            to: `/history-logs?id=${towUserStore.towContracts[0].id}`,
          },
          {
            label: 'Import Data',
            icon: 'pi pi-fw pi-file-import',
            to: `/import`,
          },
        ];
        towslipMenu.items = [
          {
            label: 'Tows',
            icon: 'game-icons:tow-truck',
            to: `/tows`,
          },
          {
            label: 'Company',
            icon: 'pi pi-fw pi-building',
            to: `/company`,
          },
          {
            label: 'Reports',
            icon: 'pi pi-fw pi-tag',
            to: `/reports`,
          },
        ];
      } else if (authStore.role === 'tow_admin') {
        dynamicContractLinks.items = [
          {
            label: 'Insights',
            icon: 'ic:twotone-auto-graph',
            to: `/insights`,
          },
          {
            label: 'Apartment complexes',
            icon: 'pi pi-fw pi-home',
            to: `/complexes-managers`,
          },
          {
            label: 'Vehicles',
            icon: 'pi pi-fw pi-car',
            to: `/vehicles`,
          },
          {
            label: 'Sticker Vehicles',
            icon: 'pi pi-fw pi-tag',
            to: `/sticker-lists`,
          },
          {
            label: 'History',
            icon: 'pi pi-fw pi-history',
            to: `/history-logs?id=${towUserStore.towContracts[0].id}`,
          },
          {
            label: 'Import Data',
            icon: 'pi pi-fw pi-file-import',
            to: `/import`,
          },
        ];

        towslipMenu.items = [
          {
            label: 'Tows',
            icon: 'game-icons:tow-truck',
            to: `/tows`,
          },
          {
            label: 'Team',
            icon: 'pi pi-fw pi-users',
            to: `/tow-team`,
          },
          {
            label: 'Company',
            icon: 'pi pi-fw pi-building',
            to: `/company`,
          },
          {
            label: 'Reports',
            icon: 'pi pi-fw pi-chart-bar',
            to: `/reports`,
          },
        ];
      }
    } else {
      if (authStore.role === 'tow_admin') {
        dynamicContractLinks.items = [
          {
            label: 'Apartment complexes',
            icon: 'pi pi-fw pi-home',
            to: `/complexes-managers`,
          },
        ];

        towslipMenu.items = [
          {
            label: 'Team',
            icon: 'pi pi-fw pi-users',
            to: `/tow-team`,
          },
          {
            label: 'Company',
            icon: 'pi pi-fw pi-building',
            to: `/company`,
          },
        ];
      }
    }

    return [dynamicContractLinks, towslipMenu, ...menuItems.value];
  } else if (
    authStore.role === 'external_user' &&
    externalUserStore.selectedContract
  ) {
    const externalUserLinks = {
      icon: 'pi pi-fw pi-home',
      visible: () => {
        return true;
      },
      items: [
        {
          label: 'Vehicles',
          icon: 'pi pi-fw pi-car',
          to: `/complexes/vehicles?id=${externalUserStore.selectedContract.id}`,
        },
      ],
    };

    return [externalUserLinks, ...menuItems.value];
  } else if (authStore.role === 'super_admin') {
    const superAdminLinks = {
      label: 'Super Admin',
      icon: 'pi pi-fw pi-home',
      visible: () => {
        return true;
      },
      items: [
        {
          label: 'Tow Companies',
          icon: 'pi pi-fw pi-users',
          to: `/super-admin/tow-companies`,
        },
        {
          label: 'External Companies',
          icon: 'mdi:account-tie-hat',
          to: `/super-admin/external-companies`,
        },
        {
          label: 'External Contracts',
          icon: 'fa-solid:user-tie',
          to: `/super-admin/external-contracts`,
        },
        {
          label: 'Vehicles',
          icon: 'pi pi-fw pi-car',
          to: `/super-admin/vehicles`,
        },
        {
          label: 'Blocked Vehicles',
          icon: 'pi pi-fw pi-ban',
          to: `/super-admin/blocked-vehicles`,
        },
        {
          label: 'Blocked Units',
          icon: 'pi pi-fw pi-ban',
          to: `/super-admin/units-blocked-vehicles`,
        },
        {
          label: 'Paid Parking',
          icon: 'pi pi-fw pi-dollar',
          to: `/super-admin/paid-parking`,
        },
        {
          label: 'Transaction Logs',
          icon: 'pi pi-fw pi-money-bill',
          to: `/super-admin/transaction-logs`,
        },
        {
          label: 'Device Management',
          icon: 'pi pi-fw pi-tablet',
          to: `/super-admin/devices`,
        },
      ],
    };
    return [superAdminLinks, ...menuItems.value];
  }
  return menuItems.value;
});
</script>
